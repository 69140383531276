import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactButton from '../components/ContactButton';

import { motion, AnimatePresence } from "framer-motion"
import Fade from 'react-reveal/Fade';

import BrandStrategy from '../assets/images/adstoria-brand-strategy.webp';
import { RiStarFill } from 'react-icons/ri'

import { FaMoneyBillWave, FaUserInjured, FaPills, FaBalanceScaleLeft } from 'react-icons/fa'
import { AiFillCreditCard, AiFillCar } from 'react-icons/ai'
import { BsArrowRight } from 'react-icons/bs'
import { MdConstruction } from 'react-icons/md'
import { ImBook } from 'react-icons/im'

import AdstoriaLeadsAddictionRehab from '../assets/images/adstoria-leads-addiction-rehab.webp';
import AdstoriaLeadsAutoAccident from '../assets/images/adstoria-leads-auto-accident.webp';
import AdstoriaLeadsCreditCard from '../assets/images/adstoria-leads-credit-card.webp';
import AdstoriaLeadsPersonalInjury from '../assets/images/adstoria-leads-personal-injury.webp';
import AdstoriaLeadsStudentLoanDebt from '../assets/images/adstoria-leads-student-loan-debt.webp';
import AdstoriaLeadsTaxDebt from '../assets/images/adstoria-leads-tax-debt.webp';
import AdstoriaLeadsHomeServices from '../assets/images/adstoria-leads-home-services.webp';
import AdstoriaMassTortQualified from '../assets/images/adstoria-mass-tort-qualified.webp';


function Leads() {
  return <>

    <Helmet>
        <title>Leads Generation - Adstoria</title>
    </Helmet>

    <Header />
    {/* <ContactButton /> */}

    <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }} >

   

    <div className="main_lead">

      <div className="main_lead--hero">
        <div className="main_lead--hero-wrapper">
          <Fade left>
          <div className="main_lead--hero-text">
            <h2>The marketing strategy used by Adstoria is unique</h2>
            <h1>Adstoria is different from other lead generation companies. You only pay for real-time transfer leads.</h1>
            <div className="main_lead--hero-text-buttons">
              {/* <div className="button-find-more">
                FIND MORE
              </div> */}
              <Link to="/contact"><div className="button-find-more">
                CONTACT
              </div></Link>
            </div>
            <div className="stars">
              <RiStarFill color="#FFD233" />
              <RiStarFill color="#FFD233" />
              <RiStarFill color="#FFD233" />
              <RiStarFill color="#FFD233" />
              <RiStarFill color="#FFD233" />
              <span>PREMIUM LEADS</span>
            </div>
          </div>
          </Fade>
          <Fade right>
          <div className="main_lead--hero-visual">
            <img src={BrandStrategy} alt="" />
          </div>
          </Fade>
        </div>
      </div>

      <div className="main_lead--content">
        <div className="main_lead--content-grid">

          <Fade bottom>
          <div className="grid-item">
            <div className="grid-item-top">
              <img src={AdstoriaLeadsTaxDebt} alt="TAX DEBT LEADS" />
              <div className="icon-container"><FaMoneyBillWave size="50px" /></div>
              <h3>TAX DEBT LEADS</h3>
              <div className="icon-container"><BsArrowRight size="50px" /></div>
            </div>
            <p>Tax relief leads are in very high demand and are sold to companies in real-time, especially on live broadcast television. These are the leads you should be looking for if you're seeking the most effective strategy to grow your business. Our professional and effective approach will draw potential customers to your services and encourage them to buy.</p>
            {/*<Link to="#"><div className="button-find-more">
              FIND MORE
              <FaClipboardList />
            </div></Link>*/}
          </div>

          <div className="grid-item">
            <div className="grid-item-top">
              <img src={AdstoriaLeadsCreditCard} alt="CREDIT CARD LEADS" />
              <div className="icon-container"><AiFillCreditCard size="50px" /></div>
              <h3>CREDIT CARD LEADS</h3>
              <div className="icon-container"><BsArrowRight size="50px" /></div>
            </div>
            <p>To bring our debt lead generation to life, we utilize many essential strategies through our unique and efficient ideas. Our services are appealing to business that help people who are in financial difficulties and are looking for a way to get through an unsecured debt issue. We provide you with options so that you have the greatest opportunity for customer conversion!</p>
            {/*<Link to="#"><div className="button-find-more">
              FIND MORE
              <FaClipboardList />
            </div></Link>*/}
          </div>

          <div className="grid-item">
            <div className="grid-item-top">
              <img src={AdstoriaLeadsAddictionRehab} alt="Addiction/Rehab Leads" />
              <div className="icon-container"><FaPills size="50px" /></div>
              <h3>Addiction/Rehab Leads</h3>
              <div className="icon-container"><BsArrowRight size="50px" /></div>
            </div>
            <p>It's difficult enough to stay one step ahead of the various marketing opportunities for addiction rehab services, so it's vital that anyone looking for mental health or addiction treatment services is approached by a clean, conversion-focused design that incorporates useful content. This is where we can make a difference with direct response television, radio and web advertising!</p>
            {/*<Link to="#"><div className="button-find-more">
              FIND MORE
              <FaClipboardList />
            </div></Link>*/}
          </div>

          <div className="grid-item">
            <div className="grid-item-top">
              <img src={AdstoriaLeadsAutoAccident} alt="AUTO ACCIDENT LEADS" />
              <div className="icon-container"><AiFillCar size="50px" /></div>
              <h3>AUTO ACCIDENT LEADS</h3>
              <div className="icon-container"><BsArrowRight size="50px" /></div>
            </div>
            <p>Every year, millions of commuters spend more time on the roads in the United States than ever before, increasing the number of auto accidents. We are motivated by the desire to provide assistance to families dealing with life-altering events by connecting them with expert legal advisers.</p>
            {/*<Link to="#"><div className="button-find-more">
              FIND MORE
              <FaClipboardList />
            </div></Link>*/}
          </div>
          </Fade>

          <Fade bottom>
          <div className="grid-item">
            <div className="grid-item-top">
              <img src={AdstoriaLeadsPersonalInjury} alt="PERSONAL INJURIY LEADS" />
              <div className="icon-container"><FaUserInjured size="50px" /></div>
              <h3>PERSONAL INJURIY LEADS</h3>
              <div className="icon-container"><BsArrowRight size="50px" /></div>
            </div>
            <p>Are you looking to expand your business? We've got you covered. With our personalized campaigns, you can acquire personal injury leads for your whole state, or multiple states if you practice throughout the country. These injured consumers need legal assistance with a wide range of personal injury issues.</p>
            {/*<Link to="#"><div className="button-find-more">
              FIND MORE
              <FaClipboardList />
            </div></Link>*/}
          </div>

          <div className="grid-item">
            <div className="grid-item-top">
              <img src={AdstoriaLeadsHomeServices} alt="HOME SERVICES LEADS" />
              <div className="icon-container"><MdConstruction size="50px" /></div>
              <h3>HOME SERVICES LEADS</h3>
              <div className="icon-container"><BsArrowRight size="50px" /></div>
            </div>
            <p>Our specialty is exclusive leads. We provide our clients with new business opportunities and unique leads to contractors in a variety of areas. We most certainly have a method to generate more leads for you if you provide any form of service to homeowners. We connect you with potential consumers that are in need of your services right now or will be in the near future.</p>
            {/*<Link to="#"><div className="button-find-more">
              FIND MORE
              <FaClipboardList />
            </div></Link>*/}
          </div>

          <div className="grid-item">
            <div className="grid-item-top">
              <img src={AdstoriaMassTortQualified} alt="MASS TORT QUALIFIED CASES" />
              <div className="icon-container"><FaBalanceScaleLeft size="50px" /></div>
              <h3>MASS TORT QUALIFIED CASES</h3>
              <div className="icon-container"><BsArrowRight size="50px" /></div>
            </div>
            <p>Adstoria is different from other lead generation companies. You only pay for real-time transfer leads. Marketing is essential if you want to increase your mass tort practice area. That’s where we got you covered - we support you in accomplishing your goals with our lead generation so you can focus on what matters most to you: your clients.</p>
            {/*<Link to="#"><div className="button-find-more">
              FIND MORE
              <FaClipboardList/>
            </div></Link>*/}
          </div>

          <div className="grid-item">
            <div className="grid-item-top">
              <img src={AdstoriaLeadsStudentLoanDebt} alt="STUDENT LOAN DEBT LEADS" />
              <div className="icon-container"><ImBook size="50px" /></div>
              <h3>STUDENT LOAN DEBT LEADS</h3>
              <div className="icon-container"><BsArrowRight size="50px" /></div>
            </div>
            <p>Student loan debt is one of the US’s most serious financial issues. Throughout the entire process, we keep your company in mind. We carefully develop our creatives, and our student loan ones are no exception. We want to help you save time and make you money!</p>
            {/*<Link to="#"><div className="button-find-more">
              FIND MORE
              <FaClipboardList />
            </div></Link>*/}
          </div>
          </Fade>

        </div>
      </div>

    </div>

            <Footer />
            </motion.div>
            </AnimatePresence>
  </>;
}

export default Leads;
