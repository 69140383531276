import React, {useState, Suspense} from 'react'
import { Helmet } from "react-helmet";

import ModalVideo from 'react-modal-video'

import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactButton from '../components/ContactButton';

import Fade from 'react-reveal/Fade';


import logobg from '../assets/images/logo-white-stroke.webp'
import Girl from '../assets/images/girl.webp'
import GirlLike from '../assets/images/girl-like.webp'

import { BsFillPlayCircleFill } from 'react-icons/bs';

import { FaAssistiveListeningSystems, FaChartBar, BiLineChart, IoRocketSharp } from "react-icons/all"

function About() {

    const [isOpen, setOpen] = useState(false)


    return (
        <>

            <Helmet>
                <title>About us - Adstoria</title>
            </Helmet>

            <Header />
           {/*  <ContactButton /> */}

            <div className="main_about">
                
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="5xsz5ZOGWBs" onClose={() => setOpen(false)} />

                <div className="main_about--top">

                

                <Fade left>
                    <div className="main_about--top--item">
                    <img lazy src={logobg} className="background" alt="background cotact adstoria logo" />
                        <h2>Who we are?</h2>
                        <p>We are the first to know that your company’s identity it’s considered to be one of the most important and valuable asset. Being part of Cold Mountain group, we form a dynamic team with creative thinking and fresh perspectives, developing innovative  work within the marketing and advertising industry for our partners.</p>
                    </div>
                </Fade>

                <Fade right>
                    <div className="main_about--top--item red">
                    <img lazy src={logobg} className="background" alt="background cotact adstoria logo" />
                        <h2>What we do?</h2>
                        <p>Implement creative branding concepts and functional marketing strategies that grow awareness and generate action. Based on your company’s values, we create without inhibitions in ways that feel natural and true for your targeted audience.</p>
                        
                        <React.Fragment>
                            

                            <div className="main_about--top--item--watch" onClick={()=> setOpen(true)}>
                                <div className="main_about--top--item--watch--content">
                                    <BsFillPlayCircleFill size="25" className="icon" />
                                    It's easier to watch a video    
                                </div>
                            </div>
                        </React.Fragment>
                        
                        
                        
                    </div>
                </Fade>

                </div>
                <h1>How we do it?</h1>
                <div className="main_about--content">
                  <div className="main_about--content--timeline">

                    <div className="step">                        
                        <div className="text">
                            <h2>We listen to your story</h2>
                            <p>We need to get familiar with your business perspectives, so tell us all about your vision and your goals, in order to raise the authenticity of your brand to the highest peaks.</p>
                        </div>
                        <div className="icon">
                            <FaAssistiveListeningSystems size="35" />
                        </div>
                    </div>

                    <div className="step">                        
                        <div className="text">
                            <h2>We analyze and find solutions</h2>
                            <p>It’s time to plan the strategy perfectly adapted to your needs, giving your brand a voice and visual identity. Basically, we turn your story into a clear and comprehensive expression.</p>
                        </div>
                        <div className="icon">
                            <FaChartBar size="35" />
                        </div>
                    </div>

                    <div className="step">                        
                        <div className="text">
                            <h2>We implement and optimize</h2>
                            <p>Our integrated strategies cross a 360° marketing journey with measurable effects, smoothly covering all services from branding, advertising campaigns, digital tactics, marketing strategies and everything in between.</p>
                        </div>
                        <div className="icon">
                            <BiLineChart size="35" />
                        </div>
                    </div>

                    <div className="step">                        
                        <div className="text">
                            <h2>We launch! Now your brand is ready to be seen, chosen and remembered!</h2>
                        </div>
                        <div className="icon hidden">
                            <IoRocketSharp size="35" />
                        </div>
                    </div>

                  </div>

                  <div className="main_about--content--visual">
                      <Suspense fallback={<div>loading</div>}>
                      <div className="girl">
                        <img lazy src={Girl} alt="Adstoria girl" />
                      </div>
                      
                      <div className="girl-like">
                        <img lazy src={GirlLike} alt="Adstoria girl like" />
                      </div>
                      </Suspense>
                  </div>



                </div>
            </div>

            <Footer />


        </>
    )
}

export default About
