import React from 'react'
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import BrandStrategy from '../assets/images/adstoria-brand-strategy.webp';
import DigitalExperience from '../assets/images/adstoria-digital-experience.webp';
import SocialMedia from '../assets/images/adstoria-social-media.webp';
import VideoProduction from '../assets/images/adstoria-video-production.webp';

import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactButton from '../components/ContactButton';

import { MdOutlineHorizontalRule } from 'react-icons/md'

import Fade from 'react-reveal/Fade';
import { motion, AnimatePresence } from "framer-motion"

function Services() {
    return (
        <>
            <Helmet>
                <title>Services - Adstoria</title>
            </Helmet>

            <Header />
            {/* <ContactButton /> */}
            
            <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }} >

            

        
            <div className="main_services border-bottom">

                <div className="main_services--item order-1">
                <Fade left>
                    <div className="main_services--item--top">
                        <div className="main_services--item--top--number">
                            1
                        </div>
                        <div className="main_services--item--top--title">
                            <h2>Brand Strategy</h2>
                        </div>
                    </div>
                    <div className="main_services--item--content">
                        <p>With deep analytical thinking, design craftsmanship, and precise touch-points execution, ADSTORIA is ready to guide you through the complete creative transformation of your company.   From this standpoint, one could view our approach as more as a business consultant with a solution based, brand-marketing emphasis. We provide branding from the inside-out, so we do this effectively by integrating branding, marketing, design, and technology in:</p>
                        <div className="main_services--item--content--list">
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Art direction
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Corporate identity
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Graphic design
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Packaging
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Photography
                            </div>
                        </div>
                    
                    </div>
                    </Fade>
                </div>

                
                <div className="main_services--item bg-color flex order-2" style={{ background: '#1C2956' }}>
                <Fade><img lazy src={BrandStrategy} alt="Adstoria Brand Strategy" /></Fade>
                </div>

                
                <div className="main_services--item bg-color flex order-3" style={{ background: '#C7203C'}}>
                <Fade><img lazy src={DigitalExperience} alt="Adstoria Digital Experience" /></Fade>
                </div>

                <div className="main_services--item order-4">
                <Fade right>
                    <div className="main_services--item--top">
                        <div className="main_services--item--top--number empty-number">
                            2
                        </div>
                        <div className="main_services--item--top--title">
                            <h2>Digital Experience</h2>
                        </div>
                    </div>
                    <div className="main_services--item--content">
                        <p>Research and strategy provide a solid foundation for the execution. Our team implements the strategy, analyses its performance and constantly optimizes it in order to acheive the best possible results.</p>
                        <div className="main_services--item--content--list">
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Digital strategy
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Web design
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Content creation
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />User experience
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />Web development
                            </div>
                            <div className="main_services--item--content--list--item">
                                <MdOutlineHorizontalRule />SEO
                            </div>
                        </div>
                    
                    </div>
                    </Fade>
                </div>

                <div className="main_services--item order-5">
                <Fade left>
                    <div className="main_services--item--top">
                        <div className="main_services--item--top--number">
                            3
                        </div>
                        <div className="main_services--item--top--title">
                            <h2>Video Production</h2>
                        </div>
                    </div>
                    <div className="main_services--item--content">
                        <p>The dynamic video ads we create show the magic that can happen when data feeds creativity</p>
                        <p>We run and optimize online video campaigns across platforms and draw on data to improve user experience and increase sales. Video advertising  make up the core of our work, generating attention to create impact – not just reach, but watch time; not just impressions, but true business results</p>
                    
                    </div>
                    </Fade>
                </div>

                
                <div className="main_services--item bg-color flex order-6" style={{ background: '#1C2956'}}>
                <Fade><img lazy src={VideoProduction} alt="Adstoria Video Production" /></Fade>
                </div>

                <div className="main_services--item bg-color flex order-7" style={{ background: '#C7203C'}}>
                <Fade><img lazy src={SocialMedia} alt="Adstoria Social Media" /></Fade>
                </div>

                <div className="main_services--item order-8">
                <Fade right>
                    <div className="main_services--item--top">
                        <div className="main_services--item--top--number empty-number">
                            4
                        </div>
                        <div className="main_services--item--top--title">
                            <h2>Social Media Marketing</h2>
                        </div>
                    </div>
                    <div className="main_services--item--content">
                        <p><b>WHAT?</b></p>
                        <p>It is important to publish content your audience will love. For that, we take care of platforms that are best fit to your brand story. We transform your social media presence with high-quality content, daily activities, monitoring-engagement and increased followers. This service will attract targeted followers to then convert into loyal customers while telling your brand’s story throughout.</p>
                        <p><b>WHY?</b></p>
                        <p>TARGETED MEDIA: Social media platforms offer a world of targeting options. This gives you the ability to narrow down your targeting methods to solely attract qualified leads and, as a result, you trim the fat on your marketing budget.</p>
                        <p>BE PRESENT: There are almost 4 billion people on social media. Regardless of the industry, you can be certain that your customers are using social media on a daily basis. Keep them engaged and connected to your brand so that you’re always their first option.</p>
                        
                    
                    </div>
                    </Fade>
                </div>

            </div>

            <div className="main_services_footer">
                <p>Do we fit your needs? Send us a message to find out more. Let's get to work!</p>
                <Link to="/contact"><div className="button">CONTACT US</div></Link>
            </div>
            

            <Footer />
            </motion.div>
            </AnimatePresence>
        </>
    )
}

export default Services
