import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

import PdmiMember from '../assets/images/pdmi.webp'

const Result =() => {
    return (
      <p>Thank you for subscribing!</p>
    )
  }

function Footer() {

    const [result, showResult] = useState(false);

    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_muok6vk', 'template_7y1o0ur', form.current, 'user_zoMOEiOLyeuQCH7z4ny6F')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        showResult(true);
    };

    return (
        <div className="main_footer">
            <p>Add your email to subscribe to newsletter. You will get best news from us</p>
            <div className="main_footer--newsletter">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="email" name="user_email" placeholder="Email..." className='input-width'/>
                    <input type="submit" value="Subscribe!" className='button'/>
                </form>

                { result ? <Result /> : null }
                
            </div>
            2022 &copy; Adstoria - Cold Mountain Group
            <img src={PdmiMember} className="pdmi-member" alt="PDMI member" />
        </div>
    )
}

export default Footer
