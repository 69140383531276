import React, { useState } from 'react';
import { Helmet } from "react-helmet";

import ModalVideo from 'react-modal-video'

import { motion, AnimatePresence } from "framer-motion"

import Logo from '../assets/images/logo.png'
import Video from '../assets/images/video_compressed2.webm'

import { Link } from "react-router-dom";

import { AiOutlineMenu } from 'react-icons/ai';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

function Home() {

    const [menu, setMenu] = useState(false);
    const showMenu = () => setMenu (!menu);

    const [isOpen, setOpen] = useState(false)
    
    return (

        <>

        <Helmet>
            <title>Adstoria - Embrace the journey</title>
        </Helmet>


        <AnimatePresence>
        <motion.div className="main_home"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <div className="main_home--info">

                <motion.div className="main_home--info--header"
                    initial={{ opacity: 0, y: -40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 2.3, duration: .8, type: 'easeOut' }}
                >

                    <Link to="/"><div className="main_home--info--header--logo">
                        <img src={Logo} alt="" />
                        <span>adstoria</span>
                    </div></Link>     

                    
                    <div className="main_home--info--header--nav-mobile">
                        <AiOutlineMenu size="25px" onClick={showMenu} />  
                    </div>

                    <nav className={menu ? 'nav-menu active' : 'nav-menu'}>                        
                        <div className="nav-menu-items">
                            <Link to="/">Home</Link>
                            <Link to="/services">Services</Link>
                            <Link to="/leads-generation">Leads Generation</Link>
                            <Link to="/about-us">About Us</Link>
                            <Link to="/contact">Contact</Link>
                        </div>                  
                    </nav>

                </motion.div>          

                <motion.div className="main_home--info--title"
                    initial={{ opacity: 0, y: -40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 2.6, duration: .8, type: 'easeOut' }}
                >
                    <h1>Embrace the journey!</h1>

                    <p>We’re an integrated marketing and creative company, the place where ideas, advertising, media and technology meet. </p>
                </motion.div>                

                <div className="main_home--info--cta">
                <Link to="/services"><motion.button
                    initial={{ opacity: 0, y: -40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 2.9, duration: .8, type: 'easeOut' }}
                >
                    Get started
                    <BsFillArrowRightCircleFill size="35" />
                    </motion.button></Link>
                </div>

            </div>

            

            <div className="main_home--video">
                <div className="main_home--video--nav">
                    <Link to="/">Home</Link>
                    <Link to="/services">Services</Link>
                    <Link to="/leads-generation">Leads Generation</Link>
                    <Link to="/about-us">About Us</Link>
                    <Link to="/contact">Contact</Link>
                </div>

                

                    <div className="main_home--video--cta">
                    <button onClick={()=> setOpen(true)}>
                        Watch video
                        <BsFillPlayCircleFill size="35" />
                    </button>
                    
                </div>
                <video lazy loop autostart autoPlay muted src={Video} />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="iHPz02jg6D4" onClose={() => setOpen(false)} />
            </div>

            
        </motion.div>
        </AnimatePresence>

    

        </>
    )
}

export default Home
