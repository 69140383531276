import React, { useRef, useState } from 'react'
import { Helmet } from "react-helmet";

import Header from '../components/Header'
import Footer from '../components/Footer'

import emailjs from '@emailjs/browser';

import logobg from '../assets/images/logo-white-stroke.webp'

import { RiPhoneFill, RiMailFill, RiMapPin2Fill } from 'react-icons/ri';
import { RiFacebookBoxFill } from "react-icons/ri";
import { RiLinkedinBoxFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { RiYoutubeFill } from "react-icons/ri";
import { RiTwitterFill } from "react-icons/ri";

import { ExternalLink } from 'react-external-link';

import Fade from 'react-reveal/Fade';

const Result =() => {
    return (
      <p>Thank you for your message! You will'be contacted as soon as possible. :)</p>
    )
  }

function Contact() {

  const [result, showResult] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_cippqmj', 'template_rpmy8f9', form.current, 'user_zoMOEiOLyeuQCH7z4ny6F')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      showResult(true);
  };

    return (
        <>

            <Helmet>
                <title>Contact - Adstoria</title>
            </Helmet>

            <Header />

            <Fade>
            <div className="main_contact">
                <div className="main_contact--informations">
                    <img lazy src={logobg} className="background" alt="background cotact adstoria logo" />
                    <div className="main_contact--informations--top">
                        <h1>Contact informations</h1>
                        <p>Feel free to ask us any questions</p>
                    </div>
                    <ul>
                        <li><RiPhoneFill size="30px" />+917-684-7265</li>
                        <li><RiMailFill size="30px" />contact@adstoria.io</li>
                        <li><RiMapPin2Fill size="30px" />30 N, Gould St, Sheridan, WY</li>
                    </ul>
                    <div className="main_contact--informations--icons">
                        <ExternalLink className="icon" href="https://www.facebook.com/AdStoria-Lab-107793971775092"><RiFacebookBoxFill size="25"  /></ExternalLink>
                        <ExternalLink className="icon" href="https://www.linkedin.com/company/adstoria-lab"><RiLinkedinBoxFill size="25"  /></ExternalLink>
                        <ExternalLink className="icon" href="https://www.instagram.com/adstoria.io/"><RiInstagramFill size="25"  /></ExternalLink>
                        <ExternalLink className="icon" href="https://www.youtube.com/channel/UClo6adnxsLZd0igSHge06GA/"><RiYoutubeFill size="25"  /></ExternalLink>
                        <ExternalLink className="icon" href="https://www.twitter.com/adstoria" target="_blank"><RiTwitterFill size="25"  /></ExternalLink>
                    </div>
                </div>


                <div className="main_contact--form">
                <p></p>
                <form ref={form} onSubmit={sendEmail}>
                <p>Let's work together to create stories for your brand. Contact us to start long term winning strategies.</p>
                    <label>Name</label>
                    <input type="text" name="user_name" minlength="5" required />
                    <label>Email</label>
                    <input type="email" name="user_email" required />
                    <label>Phone number</label>
                    <input type="tel" name="user_phone" minlength="5" required />
                    <label>Message</label>
                    <textarea name="message" />
                    <input className="button" type="submit" value="Send!" required />
                </form>
                
                </div>
                { result ? <Result /> : null }
                
            </div>

            </Fade>

            <Footer />

        </>
    )
}

export default Contact
